import React, { useEffect, useState } from "react";

function Experienced() {
    const randomInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const [randomNumber, setRandomNumber] = useState(0);

    useEffect(() => {
        if (window.innerWidth > 768) {
            setInterval(function () {
                setRandomNumber(randomInteger(1, 7))
            }, 3000);
        }
    }, []);

    return (
        <div className="container">
            <div className={`experienced experienced-${randomNumber}`}>
                <h1 className="experienced-title">Experienced in</h1>
                <div className="experienced-mobile-view">
                    UI,UX & IxD Design  /  Leading Design Processes  /  Usability Engineering & Evaluation  /  Content Audit & Web Analysis  /  Competitive Research  /  User Research
                </div>

                <div className="experienced-block">
                    <h1 className="experienced-ui experienced-ui-1">UI,UX & IxD Design</h1>
                    <h1 className="experienced-ui experienced-ui-2">Leading Design Processes</h1>
                </div>
                <div className="experienced-block">
                    <h1 className="experienced-ui experienced-ui-3">Usability Engineering & Evaluation</h1>
                    <h1 className="experienced-ui  experienced-ui-4">Content Audit & Web Analysis</h1>
                </div>
                <div className="experienced-block">
                    <h1 className="experienced-ui experienced-ui-5">Competitive Research</h1>
                    <h1 className="experienced-ui experienced-ui-6">User Research</h1>
                    <h1 className="experienced-ui  experienced-ui-7">Design Systems</h1>
                </div>
            </div>
        </div>
    );
}

export default Experienced;