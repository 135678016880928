import React from "react";
import gsap from 'gsap/all';

function Case() {

    const openCase = (caseName) => {
        const caseContainer = document.querySelector(`.${caseName}`)

        const galleryContainer = caseContainer.querySelector('.gallery-container');
        const galleryBtnClose = caseContainer.querySelector('.gallery-btn-close');

        caseContainer.querySelector('.slides').scrollTop = 0

        const tl = gsap.timeline();
        tl.to(caseContainer, {
            duration: 0,
            opacity: 1,
            top: 0,
        })

        tl.to(galleryContainer, { duration: 0.3, y: '0%' })
        tl.to(galleryBtnClose, { duration: 0.3, opacity: 1 })

        document.body.style.overflow = 'hidden';
    }

    return (
        <div className="container">
            <div className="case">
                <div className="caseHead">
                    <h1 className="case-title">Case-Studies</h1>
                    <p className="case-title-text">Last projects from my personal portfolio.</p>
                </div>

                <div className="case-main case-chrome" onClick={() => openCase('chrome-case')}>
                    <div>
                        <h1 className="case-title-1">Co-Designing Chrome Extension</h1>
                        <p className="case-date">May 2021</p>
                    </div>
                    <div className="case-btn">
                        <h1 className="case-border">Collaboration</h1>
                        <h2 className="case-border">User Research</h2>
                    </div>
                </div>


                <div className="case-main case-fintech" onClick={() => openCase('fintech-case')}>
                    <div>
                        <h1 className="case-title-1">Creating Design System for Fintech </h1>
                        <p className="case-date">April 2021</p>
                    </div>
                    <div className="case-btn">
                        <h1 className="case-border">Leading Process</h1>
                        <h2 className="case-border">UI Design</h2>
                    </div>
                </div>


                <div className="case-main case-onlineloan" onClick={() => openCase('onlineloan-case')}>
                    <div>
                        <h1 className="case-title-1">Improving UX for Online Loans</h1>
                        <p className="case-date">February - July 2019</p>
                    </div>
                    <div className="case-btn">
                        <h1 className="case-border">Usability Engineering</h1>
                    </div>
                </div>


                <div className="case-main case-mobilewallet" onClick={() => openCase('mobilewallet-case')}>
                    <div>
                        <h1 className="case-title-1">Designing Mobile Wallet</h1>
                        <p className="case-date">December 2016</p>
                    </div>
                    <div className="case-btn">
                        <h1 className="case-border">IxD Design</h1>
                        <h2 className="case-border">Concept Creation</h2>
                    </div>
                </div>


                <div className="case-main case-onlinestore" onClick={() => openCase('onlinestore-case')}>
                    <div>
                        <h1 className="case-title-1">Re-Designing Online Store</h1>
                        <p className="case-date">February 2016</p>
                    </div>
                    <div className="case-btn">
                        <h1 className="case-border">Content Audit</h1>
                        <h2 className="case-border">UI Design</h2>
                    </div>
                </div>
            </div>

            <div className="caseMobile">
                <h1 className="experienced-title">Cases</h1>
                <div className="caseMobile__text">
                    In order to get access on last projects, <br /> please visit the website from the <br /> desktop.
                </div>
            </div>
        </div >
    );
}

export default Case;