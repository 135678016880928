
import React, { useEffect } from "react";
import './Gallery.scss';

import Image1 from '../../assets/image/projects/2.1.png';
import Image2 from '../../assets/image/projects/2.2.png';
import Image3 from '../../assets/image/projects/2.3.png';
import Image4 from '../../assets/image/projects/2.4.png';
import Image5 from '../../assets/image/projects/2.5.png';
import Image6 from '../../assets/image/projects/2.6.png';
import Image7 from '../../assets/image/projects/2.7.png';
// import Image9 from '../../assets/image/projects/1.9.png';

import gsap from 'gsap/all';

function FintechCase({ images }) {
    useEffect(() => {
        const mainElement = document.querySelector('.fintech-case')
        const galleryContainer = mainElement.querySelector('.gallery-container');

        gsap.set(galleryContainer, { y: '100%' })

        const slides = mainElement.querySelector('.slides');
        slides.addEventListener('scroll', (e) => {
            // const lastElementOfScroll = e.target.scrollHeight - e.target.clientHeight;

            // if (e.target.scrollTop >= lastElementOfScroll) {
            //     setTimeout(function () {
            //         const galleryContainer = document.querySelector('.gallery-container');
            //         galleryContainer.classList.add('showNextBtn')
            //     }, 1000)
            // }
            // else {
            //     const galleryContainer = document.querySelector('.gallery-container');
            //     galleryContainer.classList.remove('showNextBtn')
            // }
        })
    }, []);



    const closeGalleryWithgsap = () => {
        const mainElement = document.querySelector('.fintech-case')

        const galleryContainer = mainElement.querySelector('.gallery-container');
        const galleryBtnClose = mainElement.querySelector('.gallery-btn-close');

        const tl = gsap.timeline();

        tl.to(galleryContainer, { duration: 0.3, y: '100%' })
        tl.to(galleryBtnClose, { duration: 0.3, opacity: 0 })
        tl.to(mainElement, { opacity: 0, y: 0 })

        tl.to(mainElement, { top: '100%' })

        document.body.style.overflow = 'auto';
    }

    const onNextClick = (nextProjectClassName) => {
        closeGalleryWithgsap();

        document.querySelector(`.${nextProjectClassName}`).click()
    }

    return (
        <>
            <div className="gallery fintech-case">
                <div className="gallery-btn simple-hover gallery-btn-close" onClick={closeGalleryWithgsap}>Close</div>
                <div className="gallery-container">
                    <div className="slides">
                        <div>
                            <img src={Image1} alt="simple" />
                        </div>
                        <div>
                            <img src={Image2} alt="simple" />
                        </div>
                        <div>
                            <img src={Image3} alt="simple" />
                        </div>
                        <div>
                            <img src={Image4} alt="simple" />
                        </div>
                        <div>
                            <img src={Image5} alt="simple" />
                        </div>
                        <div>
                            <img src={Image6} alt="simple" />
                        </div>
                        <div>
                            <img src={Image7} alt="simple" />
                        </div>
                        <div>
                            <div className="nextProjectView">
                                <div onClick={() => onNextClick('case-onlineloan')} className="gallery-btn simple-hover  gallery-btn-next">Next Project</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default FintechCase;