import React from "react";

function About() {

    return (
        <div className="container-sm">
            <div className="about">
                <h1 className="about-text text--anime">About Me</h1>
                <div className="about-desc">
                    <p className="txt">
                        Giorgi Sosebashvili is a experienced digital product designer based in Tbilisi, Georgia. He is an expert in leading design processes, including UX benchmarking and conversion rate optimization, as well as conducting research and facilitating workshops.
                    </p>
                    <p className="text2 txt">
                        Working record includes <span className="logo-hover logo-hover--redberry">Redberry</span> Agency, <span className="logo-hover logo-hover--feedc">Feedc</span> Social Media, and <span className="logo-hover logo-hover--tbc">TBC Bank</span>, which was named the Best Digital Bank in the World by Global Finance in September 2019.
                    </p>
                    <p className="text2 txt">
                        Giorgi holds several certifications including a UX Research Certification from <span className="logo-hover logo-hover--ngroup">NNGroup</span> and lectures UCD methodology in <span className="logo-hover logo-hover--commschool">COMMSchool</span>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;