import React from "react";
import CoverVector from "../assets/image/soseba-cover.svg";
import photo from "../assets/image/Profile1.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Header() {
    return (
        <div className="container">
            <div className="header">
                <div className="header-picture-container">
                    <img className="header-picture" src={CoverVector} alt="soseba-cover" />
                </div>

                <div className="headerInfo">
                    <p className="headerInfo-text">Digital Product Designer with over eight years of experience leading +30 projects, including an award- winning, 4.9-rated banking platform with 0.5M MAU and social media app with 0.1M MAU.</p>
                    <div className="bio" >
                        <img className="bio-photo" src={photo} alt="bio" />
                        <div>
                            <div className="bio-fullname">
                                <h1 className="bio-name">Giorgi</h1>
                                <h1 className="bio-name">Sosebashvili</h1>
                            </div>
                            <h2 className="bio-info">Designer, Researcher & Lecturer from Georgia</h2>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Header;