import React from "react";

function Perks() {
    return (
        <div className="container-sm">
            <div className="about perks">
                <h1 className="about-text text--anime">Perks</h1>

                <div className="about-desc">
                    <a target={'_blank'} rel="noreferrer" href="https://bootcamp.uxdesign.cc/workshops-101-a1bcd2c23d4f" className="perk">
                        <div className="perk__name">Product Design Workshops 101 <span className="perk__icon"></span></div>
                        <div className="perk__text">Jan 2023   |   3 Min Read</div>
                    </a>

                    <a target={'_blank'} rel="noreferrer" href="https://bootcamp.uxdesign.cc/essentials-for-having-sucessfull-storyboarding-sessions-f7e85c9d70bd" className="perk">
                        <div className="perk__name">Creating Design System for Fintech  <span className="perk__icon"></span></div>
                        <div className="perk__text">Jun 2022   |   5 Min Read</div>
                    </a>

                    {/* <a href="https://example.com" className="perk">
                        <div className="perk__name">Improving UX for Online Loans <span className="perk__icon"></span></div>
                        <div className="perk__text">May 2022   |   3 Min Read</div>
                    </a> */}

                    <div className="text perk-text">
                        For more follow me on
                        <a target={'_blank'} rel="noreferrer" href="https://linkedin.com/in/soseba">Linkedin</a>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Perks;