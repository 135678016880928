import React from "react";
import zoomer from "../assets/image/logo/zoomer.svg";
import feedc from "../assets/image/logo/feedc.svg";
import socar from "../assets/image/logo/socar.svg";
import redberry from "../assets/image/logo/redberry.svg";
import tbc from "../assets/image/logo/tbc.svg";
import cec from "../assets/image/logo/cec.svg";
import Marquee from "react-fast-marquee";

function Companies() {

    return (
        <div className="companies">
            <Marquee gradient={false} speed={50}>
                <img className="companies__item" src={zoomer} alt="zoomer" />
                <img className="companies__item" src={feedc} alt="feedc" />
                <img className="companies__item" src={socar} alt="socar" />
                <img className="companies__item" src={redberry} alt="redberry" />
                <img className="companies__item" src={tbc} alt="tbc" />
                <img className="companies__item" src={cec} alt="cec" />
            </Marquee>
        </div>
    );
}

export default Companies;