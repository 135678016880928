import gsap from 'gsap';


window.addEventListener('load', () => {
    setTimeout(() => {
        let posX = 0
        let posY = 0
        let mouseX = 0
        let mouseY = 0;

        gsap.to({}, 0.016, {
            repeat: -1,
            onRepeat: function () {
                posX = mouseX / window.innerWidth * 100
                posY = mouseY / window.innerHeight * 100

                let windowScrollTop = window.scrollY
                const cursor = document.querySelector(".cursor")

                gsap.set(cursor, {
                    css: {
                        left: `calc(${posX}vw - ${24 / 2}px)`,
                        top: `calc(${posY}vh -  ${24 / 2}px - ${windowScrollTop}px)`
                    }
                });

            }
        });

        window.addEventListener('mousemove', (e) => {
            mouseX = e.pageX;
            mouseY = e.pageY;
        })

        let registerMouseEvent = (item, type) => {
            const cursor = document.querySelector(".cursor")
            item.addEventListener('mouseenter', () => {
                cursor.classList.add('active')
                if (type) {
                    cursor.classList.add(type)
                }
            })
            item.addEventListener('mouseleave', () => {
                cursor.classList.remove('active')
                if (type) {
                    cursor.classList.remove(type)
                }
            })
        }

        document.querySelectorAll('a').forEach(item => {
            if (item.classList.contains('footer__icon')) return
            registerMouseEvent(item, 'link-cursor')
        })

        document.querySelectorAll('.case-main').forEach(item => {
            registerMouseEvent(item, 'case-cursor')
        })

        document.querySelectorAll('.footer__icon').forEach(item => {
            registerMouseEvent(item, 'move-cursor')
        })

        document.querySelectorAll('.logo-hover').forEach(item => {
            const hoverClass = item.classList[1]
            registerMouseEvent(item, hoverClass)
        })

        document.querySelectorAll('.simple-hover').forEach(item => {
            registerMouseEvent(item, null)
        })

        window.addEventListener('scroll', (e) => {
            const cursor = document.querySelector(".cursor")
            gsap.set(cursor, { opacity: 0, ease: 'none', })
        })

        window.addEventListener('mousemove', (e) => {
            // gsap.set(follower, {opacity: 1})
            const cursor = document.querySelector(".cursor")
            gsap.set(cursor, { ease: 'none', opacity: 1, })
        })
    }, 3000)
})
