import React, { useLayoutEffect, useRef, useState } from "react";
// Add Style
import "./assets/style/App.scss";
// Add Components
import Nav from "./components/Nav";
import Header from "./components/Header";
import Companies from "./components/Companies";
import About from "./components/About";
import Case from "./components/Case";
import Experienced from "./components/Experienced";
import Perks from "./components/Perks";
import Footer from "./components/Footer";
import gsap, { ScrollToPlugin, ScrollTrigger } from 'gsap/all';


import './mousemove.js'
import ChromeExtensionCase from "./components/Projects/ChromeExtensionCase";
import FintechCase from "./components/Projects/FintechCase";
import MobileWalletCase from "./components/Projects/MobileWalletCase";
import OnlineStoreCase from "./components/Projects/OnlineStoreCase";
import OnlineLoansCase from "./components/Projects/OnlineLoansCase";

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

function App() {
  const [appClassName, setAppClassName] = useState('content-hidden');
  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const coverElement = document.querySelector('.header-picture')
      const headerEl = document.querySelector('.headerInfo-text')
      const bioEl = document.querySelector('.bio')
      const headerLogo = document.querySelector('.header__nav--logo')
      const headerNavText = document.querySelectorAll('.header__nav--text')
      const companiesEl = document.querySelectorAll('.companies')

      const loadTimeline = gsap.timeline()

      loadTimeline.to(headerLogo, {
        rotate: 360, duration: 4,
      })

      gsap.to(headerLogo, { width: 64, height: 64, top: 0, delay: 4 - 1.7, duration: .7, })

      loadTimeline.from(coverElement, { duration: 1.5, yPercent: 110, ease: "power4", }, '-=1.5');

      loadTimeline.from(headerEl, { opacity: 0, y: 30 }, '-=1')

      let jumpTo = 1
      if (window.innerWidth < 768) { jumpTo = 0.5 }

      loadTimeline.from(bioEl, { opacity: 0, y: 30 }, `-=${jumpTo}`)

      loadTimeline.from(headerNavText, {
        opacity: 0, top: 10,
        onComplete: () => {
          setAppClassName(null)
        }
      }, '-=1')

      loadTimeline.from(companiesEl, {
        opacity: 0
      }, '-=0.5')
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  return (
    <div className={`app ${appClassName}`} ref={main}>
      <div className="cursor"></div>
      <Nav />
      <Header />
      <Companies />
      <About />
      <Case />
      <Experienced />
      <Perks />
      <Footer />

      <ChromeExtensionCase />
      <FintechCase />
      <MobileWalletCase />
      <OnlineStoreCase />
      <OnlineLoansCase />
    </div>
  );
}

export default App;
