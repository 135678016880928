import React from "react";
import logo from "../assets/image/logo/logo.svg";
import gsap from 'gsap';

function Nav() {
    const scrollDown = () => {
        gsap.to(window, { duration: 1.5, scrollTo: '.footer' });
    }

    return (
        <div className="container">
            <div className="header__nav">
                <h1 className="header__nav--text">Personal Page</h1>
                {/* <a href="#" className="header__nav--link"> */}
                <img src={logo} className="header__nav--logo" alt="soseba" />
                {/* </a> */}
                <h1 className="header__nav--text header__nav--link simple-hover" onClick={scrollDown}>Contact</h1>
            </div>
        </div>
    );
}

export default Nav;